<template>
  <div class="flex bg-p-gray-100 border border-p-gray-200 rounded-xl items-center">
    <div class="py-1 px-2 w-40 flex-shrink-0 border-r border-p-gray-200 flex items-center">
      <pp-token-preview :token="token"></pp-token-preview>
    </div>

    <div class="flex-grow px-4 py-1 font-medium text-lg">
      <pp-formatted-number
        :value="amount"
        :humanize="false"
        :max-decimal="token.decimals"
      ></pp-formatted-number>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Token from '@/domains/entities/Token'

  export default Vue.extend({
    props: {
      address: { type: String, required: true },
      amount: { type: String, required: true },
    },
    computed: {
      token() {
        return Token.query().find(this.address)
      },
    },
  })
</script>
