<template>
  <div class="text-water-600">
    <div class="text-sm flex-grow text-justify">
      <span class="mr-1">
        <span class="font-bold uppercase" v-text="title"></span>
        <span>:</span>
      </span>

      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: { type: String, default: 'Tip' },
    },
  }
</script>
