<template>
  <pp-form @submit.prevent="handleSubmit">
    <template v-slot:default="{ disabled }">
      <pp-card-body>
        <tip-card>
          Before expiry, both OT and YT are required for redemption. After expiry, only OT is
          required.
        </tip-card>
      </pp-card-body>

      <hr />

      <pp-card-body class="space-y-4">
        <pp-token-amount-input
          ref="tokenAmountInput1"
          v-if="tokenIssuer.expiresAt > $app.state.time"
          :value="inputAmount"
          @input="handleInput"
          show-token
          autocomplete="off"
          :address="tokenIssuer.ytAddress"
          :disabled="!$store.state.wallet.address"
          :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
        ></pp-token-amount-input>

        <pp-token-amount-input
          ref="tokenAmountInput2"
          label=""
          :value="inputAmount"
          @input="handleInput"
          show-token
          autocomplete="off"
          :address="tokenIssuer.otAddress"
          :disabled="!$store.state.wallet.address"
          :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
        ></pp-token-amount-input>
      </pp-card-body>

      <template v-if="outputTokenAmounts.length > 0">
        <hr />

        <pp-card-body>
          <pp-simple-data>
            <template #left>Output</template>
            <template #right>
              <LoadingIcon v-if="redeemDetailsState === 'loading'" class="h-5 w-5" />
            </template>
          </pp-simple-data>

          <div class="mt-4 space-y-4">
            <div v-for="tokenAmount in outputTokenAmounts" :key="tokenAmount.type">
              <div v-text="tokenAmount.type"></div>
              <OutputTokenAmount
                data-test="output"
                :address="tokenAmount.amount.token.address"
                :amount="tokenAmount.amount.formattedAmount()"
              />
            </div>
          </div>
        </pp-card-body>
      </template>

      <pp-card-actions>
        <pp-connect-wallet-btn v-if="!$store.state.wallet.address"></pp-connect-wallet-btn>

        <pp-btn
          v-else
          size="xl"
          class="flex w-full"
          type="submit"
          :disabled="disabled"
          data-test="btn"
          :loading="form.is('loading')"
        >
          Redeem
        </pp-btn>
      </pp-card-actions>
    </template>
  </pp-form>
</template>

<script>
  import Vue from 'vue'
  import TipCard from '@/components/TipCard.vue'
  import TokenIssuer from '@/domains/entities/TokenIssuer'
  import OutputTokenAmount from '@/components/OutputTokenAmount.vue'
  import { TokenAmount } from '@pendle/sdk'
  import LoadingIcon from '@/components/svg/LoadingIcon.vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { generateEtherscanTxUrl, formatNumber } from '@/assets/helpers'

  const debounceTime = 500

  export default Vue.extend({
    props: {
      tokenIssuerId: { type: String, required: true },
    },
    components: { TipCard, OutputTokenAmount, LoadingIcon },
    data() {
      return {
        inputAmount: '',
        outputTokenAmounts: [],
        timeout: null,
        redeemDetailsState: 'idle',
        form: createState(),
      }
    },
    computed: {
      tokenIssuer() {
        return TokenIssuer.query().find(this.tokenIssuerId)
      },
    },
    methods: {
      handleInput(value) {
        this.inputAmount = value
        this.redeemDetailsState = 'loading'

        clearTimeout(this.timeout)

        setTimeout(() => {
          this.tokenIssuer
            .contract(this.$store.getters['wallet/identity'])
            .redeemDetails(
              new TokenAmount(
                this.tokenIssuer.ot,
                this.inputAmount.length > 0 ? this.inputAmount : '0',
                false
              ),
              this.$store.state.wallet.address
            )
            .then((response) => {
              if (this.inputAmount === value) {
                this.redeemDetailsState = 'resolved'
                this.outputTokenAmounts = [
                  {
                    type: 'Redeemable',
                    amount: response.redeemableAmount,
                  },
                  {
                    type: 'Interest',
                    amount: response.interestAmount,
                  },
                ]

                this.$refs.tokenAmountInput1 && (this.$refs.tokenAmountInput1.interacted = true)
                this.$refs.tokenAmountInput2 && (this.$refs.tokenAmountInput2.interacted = true)
              }
            })
        }, debounceTime)
      },
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.tokenIssuer
              .contract(this.$store.getters['wallet/identity'])
              .redeem(new TokenAmount(this.tokenIssuer.ot, this.inputAmount, false)),
          this.form
        )
          .execute()
          .then((response) => {
            const {
              inputAmount,
              tokenIssuer: { ot, yieldBearingToken },
            } = this
            const outputAmount = this.outputTokenAmounts.reduce(
              (total, token) => total + Number(token.amount.formattedAmount()),
              0
            )
            const expiryText = ot.name.split('-').pop()
            this.$notification.success({
              title: 'Redeem',
              text: `Redeemed ${formatNumber(outputAmount)} ${
                yieldBearingToken.symbol
              } with ${formatNumber(inputAmount)} YT and OT (${expiryText}).`,
              action: {
                url: generateEtherscanTxUrl(response.hash),
                urlText: 'View on explorer',
              },
            })

            this.$emit('success', response)
          })
          .catch(() => {
            this.$notification.error({
              title: 'Redeem',
              text: `Unable to redeem ${this.tokenIssuer.yieldBearingToken.symbol}`,
            })
          })
      },
    },
  })
</script>
